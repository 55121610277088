import * as React from "react"
import { Link, graphql } from "gatsby"

// markup
const StripePage = (props) => {
  
  const startConnect = async(e) => {
    const urlParams = new URLSearchParams(window.location.search)
    const button = e.target
    alert(`startConnect for ${urlParams.get('id')}`)
    button.setAttribute("disabled", "disabled")

    // return
    fetch(`${process.env.GATSBY_API_URL}/createConnectExpress?id=${urlParams.get('id')}&email=${urlParams.get('email')}`, {
      method: "POST"
    })
      .then(response => response.json())
      .then(data => {
        if (data.url) {
          // redirect to Stripe onboarding process
          window.location = data.url;
        } else {
          button.removeAttribute("disabled");
          console.log("data", data);
        }
      });
  }
  
  return (
    <main>
      <h1 className="p-4 text-5xl">Connect to get paid</h1>
      <button onClick={startConnect} className="m-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Connect</button>
    </main>
  )
}

export default StripePage

export const query = graphql`
query {
  allTrainer {
    nodes {
      id
      firstName
      trainerPath: gatsbyPath(filePath: "/trainers/{Trainer.username}")
    }
  }
}
`